<template>
<div>
  <van-nav-bar
      class="backBox"
      title="月租服务"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />

    <div class="page">

      <div class="box">
        <div class="box_wrap">
          <div class="section section_0" @click="openLongMonthlyRent">
            <div class="section_item">
              <span class="title">办理长租</span>
              <span class="desc">开通长租车，停车更方便</span>
            </div>
          </div>

          <!-- <div class="section section_1" @click="openServerChange">
            <div class="section_item">
              <span class="title">服务变更</span>
              <span class="desc">查看服务，车牌变更，续费</span>
            </div>
          </div> -->
          <div class="section section_2" @click="openBillCenter">
            <div class="section_item">
              <span class="title">缴费记录</span>
              <span class="desc">车辆缴费信息</span>
            </div>
          </div>
          <div class="section section_3" @click="openInvoiceCenter">
            <div class="section_item">
              <span class="title">开票中心</span>
              <span class="desc">开发票</span>
            </div>
          </div>
        </div>
      </div>
  </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    }
  },
  mounted () {

  },
  computed: {

  },
  methods: {
    openLongMonthlyRent () {
      this.$router.push('/monthlyRental/MonthlyRentLong')
    },
    openServerChange () {
      this.$router.push('/monthlyRental/serviceChange')
    },
    openBillCenter () {
      // this.$router.push('/myBill')
      this.$router.push({ path: '/myBill', query: { type: 1 } })
    },
    openInvoiceCenter () {
      this.$router.push('/invoice/index')
    },
    onClickLeft () {
      this.$router.go(-1)
    }

  }
}
</script>
<style lang="scss" scoped>
.page{
  background-color: #fff;
}
.box {
  display: flex;
  flex-direction: column;
  padding: .16rem .16rem .3rem .16rem;
  // box-sizing: border-box;
  overflow: hidden;
  .box_wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .section {
      background-color: #f7f8fa;
      margin-top: .18rem;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      padding: .26rem .15rem .05rem .2rem;
      .section_item {
        margin-bottom: .23rem;
        display: flex;
        flex-direction: column;
        .title {
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: .16rem;
          font-family: PingFangSC-Medium;
          font-weight: 500;
          text-align: left;
          white-space: nowrap;
          line-height: .22rem;
          margin-right: .08rem;
        }
        .desc {
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: .12rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: .17rem;
          margin-top: .05rem;
        }
      }
    }
    .section_0 {
      background: url('../../../assets/img/longmonthlyrentback@2x.png') 100%
        no-repeat;
      background-size: 100% 100%;
    }
    .section_1 {
      background: url('../../../assets/img/serverchangeback@2x.png') 100%
        no-repeat;
      background-size: 100% 100%;
    }
    .section_2 {
      background: url('../../../assets/img/jiaofeijilubackimg@2x.png') 100%
        no-repeat;
      background-size: 100% 100%;
    }
    .section_3 {
      background: url('../../../assets/img/kaipiaozhongxin@2x.png') 100%
        no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
